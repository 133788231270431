import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import api from '../utils/api'
import setAuthToken from '../utils/setAuthToken'

const LoginModel = {
  email: '',
  password: ''
}

export default function SingIn() {
  let navigate = useNavigate();
  useEffect(()=>{
    if(localStorage.getItem('token'))
    navigate("/projects");
  },[])
  const [formData, setFromData] = useState(LoginModel)
  const [error, setError] = useState(false)
  const save = async () => {
    try {
      let res = await api.post('auth/login', formData)
      setError(false)
      setAuthToken(res.data.token)
      navigate("/projects");
    } catch (error) {
      setError(error.response.data.msg)
      setTimeout(() => {
        setError(false)
      }, 10000);
    }
  }
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      flexFlow={"column"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} w={"md"} py={2} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            {!error ? '' :
              <Alert status='error'>
                <AlertIcon />
                <AlertTitle>{error}</AlertTitle>
              </Alert>
            }
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" value={formData.email} onChange={e=>setFromData({...formData, ...{email: e.target.value}})} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" value={formData.password} onChange={e=>setFromData({...formData, ...{password: e.target.value}})} />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={save}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} w={"md"} py={6} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <Heading lineHeight={1.1} fontSize={{ base: "3md" }}>
              You don't have an account?
            </Heading>
            <Stack spacing={6}>
              <Button
                bg={"red.400"}
                color={"white"}
                _hover={{
                  bg: "red.500",
                }}
              >
                <RouteLink
                  to={"/signup"}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Sign Up
                </RouteLink>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
