import { Button, InputGroup, Input, InputRightElement } from '@chakra-ui/react'

export default function CopyInput({type, keyId}){
    const copy = () => {
        navigator.clipboard.writeText(keyId);
    }
    return <InputGroup size='md'>
        <Input
            pr='4.5rem'
            type={'text'}
            placeholder={type}
            disabled
            value={keyId}
        />
        <InputRightElement width='4.5rem'>
            <Button h='1.75rem' size='sm' onClick={copy}>
                Copy
            </Button>
        </InputRightElement>
    </InputGroup>
}