import { Container, chakra, useColorModeValue, Flex, Spacer, Button, Divider, FormControl, FormLabel, Checkbox, Box, Text} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import CopyInput from '../../../components/CopyInput';
import api from '../../../utils/api';

export default function ProjectFormDetail(){
    let navigate = useNavigate();
    let { projectID, formId } = useParams();
    const toList = () => {
        navigate(`/project/${projectID}`);
    }
    const [formInfo, setFormInfo] = useState(null)
    const [devWithUrl, setDevWithUrl] = useState(false)
    const [prodWithUrl, setProdWithUrl] = useState(false)
    const getForm = async() => {
        try {
            let res = await api.get(`/form/byId/${formId}`)
            setFormInfo(res.data[0])
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getForm()
    },[])
    const [showKeys, setShowKeys] = useState(false)
    return <Container maxW={'5xl'} py={12}>
        <Flex alignItems={'center'}>
            <chakra.h1
                py={5}
                fontSize={36}
                fontWeight={'bold'}
                color={useColorModeValue('gray.700', 'gray.50')}>
                Form
            </chakra.h1>
            <Spacer />
            <Button onClick={toList}>Cancel</Button>
        </Flex>
        <Divider mt={4} />
        <Flex alignItems={'center'}>
            <chakra.h1
                py={5}
                fontSize={18}
                fontWeight={'bold'}
                color={useColorModeValue('gray.700', 'gray.50')}>
                API Keys
            </chakra.h1>
            <Spacer />
            <Button variant='ghost' onClick={()=>setShowKeys(!showKeys)}>
                {showKeys ? 'Hide':'Show'}
            </Button>
        </Flex>
        <Divider mb={4} />
        <Flex flexFlow={'column'} display={showKeys ? 'flex':'none'}>
            <FormControl py={3}>
                <FormLabel>API URL</FormLabel>
                <CopyInput type='API URL' keyId={`https://api.sukces24.usermd.net/api/v1/{KEY_ID}`} />
            </FormControl>
            {
                formInfo !== null ? <Box>
                <FormControl py={3}>
                    <FormLabel>Dev Key ID</FormLabel>
                    <CopyInput type='Dev Key ID' keyId={devWithUrl ? `https://api.sukces24.usermd.net/api/v1/${formInfo.DEV_KEY}` : formInfo.DEV_KEY} />
                </FormControl> 
                <FormControl pb={2}>
                    <Checkbox name='devWithUrl' checked={devWithUrl} onChange={()=>setDevWithUrl(!devWithUrl)}>With URL</Checkbox>
                </FormControl>
                </Box>: ''
            }
            {
                formInfo !== null && formInfo.PROD_KEY ? <Box>
                <FormControl py={3}>
                    <FormLabel>Prod Key ID</FormLabel>
                    <CopyInput type='Prod Key ID' keyId={prodWithUrl ? `https://api.sukces24.usermd.net/api/v1/${formInfo.PROD_KEY}` : formInfo.PROD_KEY} />
                </FormControl> 
                <FormControl pb={2}>
                    <Checkbox name='prodWithUrl' checked={prodWithUrl} onChange={()=>setProdWithUrl(!prodWithUrl)}>With URL</Checkbox>
                </FormControl>
                </Box>: ''
            }
            <Box my={4}>
                <Text fontWeight={'bold'}>Usage:</Text>
                <Text mt={4} fontWeight={'bold'}>New Record</Text>
                <Text>Url: Dev/Prod</Text>
                <Text>Method: POST</Text>
                <Text>JSON: Usage your structure</Text>
                <Text mt={4} fontWeight={'bold'}>Get Records</Text>
                <Text>Url: Dev/Prod</Text>
                <Text>Method: Get</Text>
            </Box>
            {/* <FormControl py={3}>
                <FormLabel>Test Key ID</FormLabel>
                <CopyInput type='Test Key ID' keyId={'YOUR API KEY'} />
                <FormHelperText>Test URL: www.url.pl</FormHelperText>
            </FormControl> */}
        </Flex>
        <Divider mt={4} />
        <Divider mb={4} />
    </Container>
}