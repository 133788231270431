import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Alert,
    AlertIcon,
    AlertTitle,
  } from '@chakra-ui/react';
  import { useEffect, useState } from 'react';
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
  import { Link as RouteLink, useNavigate } from "react-router-dom";
  import api from '../utils/api'
  
  const registerModel = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    role: 'developer'
  }

  export default function SignUp() {
    let navigate = useNavigate();
    useEffect(()=>{
      if(localStorage.getItem('token'))
      navigate("/projects");
    },[])
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFromData] = useState(registerModel)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const save = async () => {
      try {
        let res = await api.post('users/register', formData)
        setError(false)
        setSuccess(res.data.msg)
        setTimeout(() => {
          setSuccess(false)
          navigate("/login");
        }, 3000);
      } catch (error) {
        setError(error.response.data.msg)
        setTimeout(() => {
          setError(false)
        }, 10000);
      }
    }

    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'} textAlign={'center'}>
              Sign up
            </Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              to enjoy all of our cool features ✌️
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              {!error ? '' :
                <Alert status='error'>
                  <AlertIcon />
                  <AlertTitle>{error}</AlertTitle>
                </Alert>
              }
              {!success ? '' :
                <Alert status='success'>
                  <AlertIcon />
                  <AlertTitle>{success}</AlertTitle>
                </Alert>
              }
              <HStack>
                <Box>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input type="text" value={formData.firstName} onChange={e=>setFromData({...formData, ...{firstName: e.target.value}})} />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName">
                    <FormLabel>Last Name</FormLabel>
                    <Input type="text" value={formData.lastName} onChange={e=>setFromData({...formData, ...{lastName: e.target.value}})} />
                  </FormControl>
                </Box>
              </HStack>
              <Box>
                  <FormControl id="nick">
                    <FormLabel>Nick</FormLabel>
                    <Input type="text" value={formData.userName} onChange={e=>setFromData({...formData, ...{userName: e.target.value}})} />
                  </FormControl>
                </Box>
              {/* <FormControl id="username">
                <FormLabel>Username</FormLabel>
                <Input type="text" />
              </FormControl> */}
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input type="email" value={formData.email} onChange={e=>setFromData({...formData, ...{email: e.target.value}})} />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input type={showPassword ? 'text' : 'password'} value={formData.password} onChange={e=>setFromData({...formData, ...{password: e.target.value}})} />
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={save}>
                  Sign up
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={'center'}>
                  Already a user? <RouteLink to={'/login'}><Link color={'blue.400'}>Login</Link></RouteLink>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }