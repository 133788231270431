import React, {useState} from 'react'
import { Stack, Text, Flex, Spacer, Divider} from '@chakra-ui/react'
import {ChevronRightIcon} from '@chakra-ui/icons'
import { useNavigate } from "react-router-dom";

export default function ProjectCard({id, title}){
    let navigate = useNavigate();
    const [shadow, setShadow] = useState('md');
    return <Stack p="4" boxShadow={shadow} m="4" rounded={'md'} onMouseEnter={()=>setShadow('xl')} onMouseLeave={()=>setShadow('md')}  borderWidth="1px" cursor={'pointer'} onClick={()=>navigate(`/project/${id}`)}> 
        <Flex alignItems={'center'}>
            <Text>{title}</Text>
            <Spacer />
            <Flex alignItems={'center'} >
                <Stack direction='row' h='70px' p={4}>
                    <Divider orientation='vertical' />
                </Stack>
                <ChevronRightIcon w={6} h={6} />
            </Flex>
        </Flex>        
    </Stack>
}