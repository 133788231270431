import { Container, chakra, useColorModeValue, Flex, Spacer, Button, Divider, SimpleGrid } from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import api from '../../utils/api';
import FormItem from '../../components/FormItem';

export default function ProjectDetail(){
    let navigate = useNavigate();
    const toList = () => {
        navigate("/projects");
    }
    let [forms, setForms] = useState([])
    let { projectID } = useParams();
    const getForms = async() => {
        try {
            let res = await api.get(`/form/list/${projectID}`)
            setForms(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getForms()
    },[projectID])
    const [showForms, setShowForms] = useState(false)
    return <Container maxW={'5xl'} py={12}>
        <Flex alignItems={'center'}>
            <chakra.h1
                py={5}
                fontSize={36}
                fontWeight={'bold'}
                color={useColorModeValue('gray.700', 'gray.50')}>
                Project
            </chakra.h1>
            {/* <EditIcon ml={4} fontSize={24} cursor={'pointer'} /> */}
            <Spacer />
            <Button onClick={toList}>Cancel</Button>
        </Flex>
        <Divider mt={4} />
        <Flex alignItems={'center'}>
            <chakra.h1
                py={5}
                fontSize={18}
                fontWeight={'bold'}
                color={useColorModeValue('gray.700', 'gray.50')}>
                Forms
            </chakra.h1>
            <Spacer />
            <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' size='sm' mr={2} onClick={()=>navigate(`/project/${projectID}/newForm`)}>New Form</Button>
            <Button variant='ghost' onClick={()=>setShowForms(!showForms)}>
                {showForms ? 'Hide':'Show'}
            </Button>
        </Flex>
        <Divider mb={4} />
        <SimpleGrid columns={{sm: 1}} spacing='2rem' display={showForms ? 'grid' : 'none'}>
            {forms.map(form => <FormItem key={form._id} formData={form} />)}
        </SimpleGrid>
    </Container>
}