import { 
    Container, 
    chakra, 
    useColorModeValue, 
    Flex,
    Button,
    Divider,
    Input,
    FormLabel,
    FormControl,
    Spacer,
    Select,
    Box,
} from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import FormField from '../../../components/FormField';
import api from '../../../utils/api'

const FormModel = {
    name: '',
    type: ''
}

const FieldModel = {
    fieldName: '',
    type: '',
    isRequired: false
}

export default function ProjectFormNew(){
    let navigate = useNavigate();
    let { projectID } = useParams();
    const [formData, setFormData] = useState(FormModel)
    const [formFields, setFormFields] = useState([]);
    const [showFields, setShowFields] = useState(true)
    const [error, setError] = useState(false)
    const addField = () => {
        let arr = [...formFields]
        arr.push({...FieldModel})
        setFormFields(arr)
    }
    const updateField = (index, e) => {
        let arr = [...formFields]
        if(e.target.name === 'isRequired')
            arr[index][e.target.name] = e.target.checked
        else
            arr[index][e.target.name] = e.target.value
        setFormFields(arr)
    }
    const deleteField = index => {
        let arr = [...formFields]
        arr.splice(index, 1)
        setFormFields(arr)
    }
    const save = async (e) => {
        e.preventDefault();
        let dataToSave = {...formData}
        dataToSave.projectId = projectID
        dataToSave.structure = [...formFields]
        try {
            let res = api.post('/form/new', dataToSave)
            console.log(res)
            navigate(`/project/${projectID}`)
        } catch (error) {
            console.log(error)
            alert("ERROR!")
        }
    }
    return <Container maxW={'5xl'} py={12}>
        <Flex alignItems={'center'}>
            <chakra.h1
                py={5}
                fontSize={36}
                fontWeight={'bold'}
                color={useColorModeValue('gray.700', 'gray.50')}>
                New Form
            </chakra.h1>
            <Spacer />
            <Button onClick={()=>navigate(`/project/${projectID}`)}>Cancel</Button>
        </Flex>
        <Divider mb={4} />
        <form onSubmit={save}>
            <FormControl isRequired py={4}>
                <FormLabel htmlFor='projectName'>Name</FormLabel>
                <Input id='projectName' type='text' required value={formData.name} onChange={e=>setFormData({...formData, ...{name: e.target.value}})} />
            </FormControl>
            <FormControl py={4}>
                <FormLabel htmlFor='projectName'>Form Type</FormLabel>
                <Select placeholder='Select form type' value={formData.type} onChange={e=>setFormData({...formData, ...{type: e.target.value}})}>
                    <option value='contact_form'>Contact Form</option>
                </Select>
            </FormControl>
            <Divider mt={4} />
            <Flex alignItems={'center'}>
                <chakra.h1
                    py={5}
                    fontSize={18}
                    fontWeight={'bold'}
                    color={useColorModeValue('gray.700', 'gray.50')}>
                    Fields
                </chakra.h1>
                <Spacer />
                <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' size='sm' mr={2} onClick={addField}>New Field</Button>
                <Button variant='ghost' onClick={()=>setShowFields(!showFields)}>
                    {showFields ? 'Hide':'Show'}
                </Button>
            </Flex>
            <Divider mb={4} />
            <Box display={showFields ? 'block':'none'}>
                {
                    formFields.map((field, i) => <FormField key={i} index={i} field={field} updateField={updateField} deleteField={deleteField} />)
                }
                {formFields.length > 0 ? <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' size='sm' mr={2} onClick={addField}>New Field</Button> : ''}
            </Box>
            <Divider mt={4} />
            <Button type='submit' colorScheme='teal' variant='solid' mt={8} width={'100%'}>
                Save Form
            </Button>
        </form>
    </Container>
}