import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import Landing from "./pages/Landing";
import SignIn from "./pages/Signin";
import SignUp from "./pages/Signup";
import Navbar from "./components/Navbar";
import ProjectList from "./pages/Project/List";
import ProjectNew from "./pages/Project/New";
import { useEffect, useState } from "react";
import api from './utils/api'
import setAuthToken from './utils/setAuthToken'
import ProjectDetail from "./pages/Project/Detail";
import ProjectFormNew from "./pages/Project/Forms/New";
import ProjectFormDetail from "./pages/Project/Forms/Detail";

const ProtectedRoute = ({children}) => {
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(false);
  useEffect(()=>{
    const authh = async () => {
      try {
        await api.get('auth')
        setLoading(false)
        setAuth(true)
      } catch (error) {
        if(localStorage.getItem('token'))
          localStorage.removeItem('token')
        setLoading(false)
        setAuth(false)
      }
    }
    authh()
  },[])
  return loading ? <div>LOADING</div> : auth ? children : <Navigate to='/login' />
}

function App() {
  if(localStorage.getItem('token'))
    setAuthToken(localStorage.getItem('token'))
  return (
    <ChakraProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/project/new" element={
            <ProtectedRoute>
              <ProjectNew />
            </ProtectedRoute>} 
          />
          <Route path="/project/:projectID" element={
            <ProtectedRoute>
              <ProjectDetail />
            </ProtectedRoute>} 
          />
          <Route path="/project/:projectID/update" element={
            <ProtectedRoute>
              <ProjectDetail />
            </ProtectedRoute>} 
          />
          <Route path="/project/:projectID/newForm" element={
            <ProtectedRoute>
              <ProjectFormNew />
            </ProtectedRoute>} 
          />
          <Route path="/project/:projectID/form/:formId" element={
            <ProtectedRoute>
              <ProjectFormDetail />
            </ProtectedRoute>} 
          />
          <Route path="/projects" element={
            <ProtectedRoute>
              <ProjectList />
            </ProtectedRoute>} 
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
