import { 
    Container, 
    chakra, 
    useColorModeValue, 
    Flex,
    Button,
    Divider,
    Input,
    FormLabel,
    FormControl,
    Spacer
} from '@chakra-ui/react'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import api from '../../utils/api'

const ProjectModel = {
    name: '',
    prodUrl: '',
    devUrl: '',
    testUrl: '',
}

export default function ProjectNew(){
    let navigate = useNavigate();
    const toList = () => {
        navigate("/projects");
    }
    const [formData, setFormData] = useState(ProjectModel)
    const [error, setError] = useState(false)
    const save = async (e) => {
        e.preventDefault();
        try {
            await api.post('projects/new', formData)
            navigate("/projects");
          } catch (error) {
            setError(error.response.data.msg)
            setTimeout(() => {
              setError(false)
            }, 10000);
          }
    }
    return <Container maxW={'5xl'} py={12}>
        <Flex alignItems={'center'}>
            <chakra.h1
                py={5}
                fontSize={36}
                fontWeight={'bold'}
                color={useColorModeValue('gray.700', 'gray.50')}>
                New Project
            </chakra.h1>
            <Spacer />
            <Button onClick={toList}>Cancel</Button>
        </Flex>
        <Divider mb={4} />
        <form onSubmit={save}>
            <FormControl isRequired py={4}>
                <FormLabel htmlFor='projectName'>Name</FormLabel>
                <Input id='projectName' type='text' required value={formData.name} onChange={e=>setFormData({...formData, ...{name: e.target.value}})} />
            </FormControl>
            <FormControl py={4}>
                <FormLabel htmlFor='prodUrl'>Prod URL</FormLabel>
                <Input id='prodUrl' type='url' value={formData.prodUrl} onChange={e=>setFormData({...formData, ...{prodUrl: e.target.value}})} />
            </FormControl>
            <FormControl py={4}>
                <FormLabel htmlFor='testUrl'>Test URL</FormLabel>
                <Input id='testUrl' type='url' value={formData.testUrl} onChange={e=>setFormData({...formData, ...{testUrl: e.target.value}})} />
            </FormControl>
            <FormControl py={4}>
                <FormLabel htmlFor='devUrl'>Dev URL</FormLabel>
                <Input id='devUrl' type='url' value={formData.devUrl} onChange={e=>setFormData({...formData, ...{devUrl: e.target.value}})} />
            </FormControl>
            <Button type='submit' colorScheme='teal' variant='solid' mt={6} width={'100%'}>
                Save
            </Button>
        </form>
    </Container>
}