import { useColorModeValue, Flex, Box, Text, Stack, Divider, Spacer } from '@chakra-ui/react'
import {ChevronRightIcon} from '@chakra-ui/icons'
import { useNavigate, useParams } from 'react-router-dom'

export default function FormItem({formData}){
    let navigate = useNavigate()
    let { projectID } = useParams();
    return <Flex w="full" alignItems="center" justifyContent="center" cursor={'pointer'} onClick={()=>navigate(`/project/${projectID}/form/${formData._id}`)}>
        <Box
            minWidth={'100%'}
            bg={useColorModeValue('white', 'gray.800')}
            maxW="sm"
            borderWidth="1px"
            rounded="lg"
            shadow="lg"
            position="relative">
            <Box p="6">
                <Flex alignItems={'center'}>
                    <Flex flexFlow={'column'}>
                        <Text mb={2} fontWeight={'medium'} fontSize={'md'}>{formData.name}</Text>
                        <Text color={'gray'} fontSize={'sm'}>Type: {formData.type}</Text>
                        {/* <IconButton
                            mt={6}
                            colorScheme='teal'
                            aria-label='Send email'
                            icon={<ViewIcon />}
                        /> */}
                    </Flex>
                    <Spacer />
                    <Flex alignItems={'center'} >
                        <Stack direction='row' h='70px' p={4}>
                            <Divider orientation='vertical' />
                        </Stack>
                        <ChevronRightIcon w={6} h={6} />
                    </Flex>
                </Flex>
            </Box>
        </Box>
    </Flex>
}