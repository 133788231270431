import { VStack, StackDivider, Container, chakra, useColorModeValue, Flex, Spacer, Button, Divider} from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
import ProjectCard from '../../components/ProjectCard';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import api from '../../utils/api';

export default function ProjectList(){
    let navigate = useNavigate();
    const newProject = () => {
        navigate("/project/new");
    }
    const [projectsList, setProjectsList] = useState([])
    const getList = async() => {
        try {
            let res = await api.get('/projects/list');
            setProjectsList(res.data)
            console.log(res)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getList()
    },[])
    return <Container maxW={'5xl'} py={12}>
        <Flex alignItems={'center'}>
            <chakra.h1
                py={5}
                fontSize={36}
                fontWeight={'bold'}
                color={useColorModeValue('gray.700', 'gray.50')}>
                My Projects
            </chakra.h1>
            <Spacer />
            <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' onClick={newProject}>
                New Project
            </Button>
        </Flex>
        <Divider mb={4} />
        <VStack
            divider={<StackDivider borderColor='gray.100' />}
            spacing={4}
            align='stretch'
        >
            {projectsList.map((project, index) => <ProjectCard key={index} id={project._id} title={project.name} />)}
            {
                projectsList.length > 0 ? '' :
                <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' onClick={newProject}>
                    Create New Project
                </Button>
            }
        </VStack>
    </Container>
}