import { 
    Input,
    FormLabel,
    FormControl,
    Select,
    Checkbox,
    Box,
    IconButton,
    Flex,
    FormHelperText
} from '@chakra-ui/react'
import {DeleteIcon} from '@chakra-ui/icons'

export default function FormField({index, field, updateField, deleteField}){

    return <Box p={4} my={4} boxShadow={'md'}>
        <Flex justifyContent={'end'}>
            <IconButton
                variant='outline'
                colorScheme='red'
                aria-label='Delete'
                size='sm'
                icon={<DeleteIcon />}
                onClick={()=>deleteField(index)}
            />
        </Flex>
        <FormControl isRequired py={2}>
            <FormLabel htmlFor={`field${index}`}>Field Name</FormLabel>
            <Input id={`field${index}`} name='fieldName' type='text' required value={field.fieldName} onChange={e=>updateField(index, e)} />
            <FormHelperText>REMEMBER! Don't use space!</FormHelperText>
        </FormControl>
        <FormControl py={2}>
            <FormLabel htmlFor='projectName'>Field Type</FormLabel>
            <Select name='type' placeholder='Select field type' value={field.type} onChange={e=>updateField(index, e)}>
                <option value='text'>Text</option>
                <option value='email'>Email</option>
                <option value='number'>Number</option>
            </Select>
        </FormControl>
        <FormControl py={2}>
            <Checkbox name='isRequired' checked={field.isRequired} onChange={e=>updateField(index, e)}>Field Is Required</Checkbox>
        </FormControl>
    </Box>
}